import * as React from 'react';
import { css } from "@emotion/core";
import {Link} from "@fitplan/lib/components/Link";

import PageWrapper from '../components/pageWrapper/PageWrapper';

const sanity = {
  title: 'WHAT THE ?!',
  info: "Well... that was awkward. The page you requested wasn't found.",
  back: 'Return to the',
  homepage: 'Homepage.',
};

const NotFoundPage = props => (
  <PageWrapper
    {...props}
    css={css`
      overflow: hidden;
      background-color: rgb(34, 39, 46);
      @media screen and (min-width: 768px) {
        height: calc(100vh - 340px);
        min-height: auto;
      }
      @media screen and (min-width: 900px) {
        min-height: calc(100% - 232px);
      }
    `}
  >
    <video
      loop
      muted
      autoPlay
      poster="img/videoframe.jpg"
      css={css`
        position: relative;
        margin-bottom: -5px;
        height: 64vh;
        transform: translate(-25%, 0%);
        @media screen and (max-width: 767px) {
          height: calc(100vh - 240px);
        }
        @media screen and (min-width: 768px) {
          position: relative;
          margin-bottom: -5px;
          height: 84vh;
          -webkit-transform: translate(-20%, 0%);
          -ms-transform: translate(-20%, 0%);
          transform: translate(-20%, 0%);
        }
        @media screen and (min-width: 769px) {
          width: 100%;
          height: 84vh;
          -webkit-transform: translate(0%, 0%) scale(2);
          -ms-transform: translate(0%, 0%) scale(2);
          transform: translate(0%, 0%) scale(2);
        }
      `}
    >
      <source
        src="https://s3-us-west-2.amazonaws.com/www.fitplanapp.com/videos/404-page/mistake-rob.mp4"
        type="video/webm"
      />
    </video>
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 15px;
        @media (min-width: 768px) {
          position: absolute;
          align-items: flex-start;
          color: white;
          left: 50%;
          top: 40%;
        }
      `}
    >
      <h1
        css={css`
          font-family: league-gothic, sans-serif;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-size: 73.6px;
          font-weight: 300;
          height: 83px;
          letter-spacing: 1px;
          line-height: 73.6px;
          margin: auto;
          margin-bottom: 10px;
        `}
      >
        {sanity.title}
      </h1>
      <div
        css={css`
          font-family: nimbus-sans, 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.4em;
        `}
      >
        <p>{sanity.info}</p>
        <p>
          {sanity.back}{' '}
          <Link
            css={css`
              text-decoration: none;
              color: #fff;
            `}
            to="https://fitplan.app.link/fitness-transformed"
          >
            <strong>{sanity.homepage}</strong>
          </Link>
        </p>
      </div>
    </div>
  </PageWrapper>
);

export default NotFoundPage;
